<template>
  <v-app>
    <v-app-bar app height="80" elevate-on-scroll>
      <v-row align="center">
        <v-col cols="12" class="pt-0 pb-0 navbar">
          <Logo viewBox="0 0 120.1 17.6" />
          <div class="link-container">
            <a class="link" href="https://salussafety.io/contact-us"
              >Contact Sales</a
            >
            <a class="link" href="tel:1-800-521-6052">1-800-521-6052</a>
          </div>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-app-bar>
    <v-main>
      <div class="title">
        <h1>
          A COST-BENEFIT ANALYSIS FOR <br />
          DIGITAL SAFETY
        </h1>
        <p>ANSWER A FEW QUESTIONS AND WE WILL GIVE YOU THE RESULTS.</p>
      </div>
      <v-container>
        <v-row justify="center">
          <v-col xs="12" sm="12" md="10" lg="10" xl="6">
            <questionnaire v-bind:questions="questionnaire" :parent="false">
            </questionnaire>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col xs="12" sm="12" md="10" lg="10" xl="6">
            <v-btn
              color="secondary"
              outlined
              v-on:click.stop="verifyBeforeShowFormSubmitModal"
            >
              Get my Results
              <!--              :disabled="progress"-->
            </v-btn>
          </v-col>
        </v-row>
        <v-row justify="center" class="pt-2">
          <v-alert v-if="message" type="error">
            {{ message }}
          </v-alert>
        </v-row>
        <SubmissionForm :payload="apiPayload" />
      </v-container>
    </v-main>
    <form id="mktoForm_1245"></form>
    <Footer />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import questionnaire from "@/components/Questionnaire.vue";
import submissionForm from "@/components/SubmissionForm.vue";
import footer from "@/components/Footer.vue";
import api from "@/services/api/endpoints";
import Logo from "@/assets/salus-logo-light-cropped.svg";
import calculation from "@/calculation";
import axios from "axios";

//Set of constants to use, render and calculate
const questions = api.getQuestions();

export default {
  components: {
    Questionnaire: questionnaire,
    SubmissionForm: submissionForm,
    Footer: footer,
    Logo,
  },
  data() {
    return {
      questionnaire: questions,
      message: "",
      apiPayload: {},
    };
  },
  computed: {
    ...mapGetters({
      //message: "landingPage/message",
    }),
  },
  watch: {
    dialog(dialog) {
      if (!dialog) {
        this.message("");
      }
    },
  },
  mounted() {
    //this.close();
  },
  methods: {
    ...mapActions({
      open: "modal/open",
      close: "modal/close",
      //setMessage: "landingPage/setMessage",
    }),
    verifyBeforeShowFormSubmitModal() {
      //TODO: Remove logs after testing in staging
      this.message = "";
      const { isFormValid, formMessage } = calculation.checkFormAnswers(
        this.questionnaire
      );
      this.message = formMessage;
      if (!isFormValid) return;

      const { isCalculationValid, apiPayload, calculationMessage } =
        calculation.calculateCostBenefit(this.questionnaire);

      this.message = calculationMessage;
      this.apiPayload = apiPayload;
      if (!isCalculationValid) return;

      //Finally display Modal
      //this.showModal();
      window.MktoForms2.loadForm(
        "//try.salussafety.io",
        "301-FFY-158",
        1245,
        function (form) {
          window.MktoForms2.lightbox(form).show();

          form.vals({ MktoCompanyNotes: JSON.stringify(apiPayload) });

          // Add an onSuccess handler
          form.onSuccess(function (values, followUpUrl) {
            console.log(values, followUpUrl);
            // Get the form's jQuery element and hide it
            // location.href = "https://salussafety.io/";

            const answers = {
              firstName: values.FirstName,
              lastName: values.LastName,
              email: values.Email,
              companyName: values.Company,
              workerCount: apiPayload.workerCount,
              subcontractorCount: apiPayload.subcontractorCount,
              adminTime: apiPayload.adminTime,
              volumeOfPaper: apiPayload.volumeOfPaper,
              estTotalCost: apiPayload.estTotalCost,
              estCostBenefit: apiPayload.estCostBenefit,
            };

            axios
              .post("/submit/", answers)
              .then(function () {
                setTimeout(function () {
                  window.location.href = "https://salussafety.io/";
                  //form.getFormElem().hide();
                }, 5000);
              })
              .catch(function (error) {
                console.log(error);
              });

            // Return false to prevent the submission handler from taking the lead to the follow-up url
            return false;
          });
        }
      );
    },
    showModal() {
      this.open();
    },
  },
};
</script>

<style scoped>
.v-app-bar {
  padding: 0 34px;
}

@media (max-width: 992px) {
  .v-app-bar {
    padding: 0 20px;
  }
}

.navbar {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.title {
  text-align: center;
  font-family: "Lato";
  line-height: 2.8rem;
  padding-top: 100px;
}

.title h1 {
  font-size: 2.2rem;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 18px;
}

.title p {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  color: #64affc;
}

.link-container {
  max-width: 250px;
  display: none;
}

.link {
  font-size: 13px;
  text-decoration: none;
  color: white;
  margin-right: 25px;
}

svg {
  height: 27px;
  width: 180px;
}

.theme--dark.v-application {
  background: #021e33;
}

.theme--dark.v-app-bar.v-toolbar.v-sheet {
  background: #021e33;
}

@media (min-width: 575px) {
  .link-container {
    display: block;
  }
}
</style>
