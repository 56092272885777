<template>
    <footer style="margin-top: 65px;">
      <div class="container">
        <div class="logo-social-container">
          <div class="salus-logo-container">
            <Logo viewBox="0 0 120.1 17.6" />
          </div>
          <div class="social-container">
            <ul>
              <li><a target="_blank" rel="noopener" href="https://www.linkedin.com/company/salus-technologies/"><font-awesome-icon icon="fab fa-linkedin-in" size="xl" :style="{ color: 'white' }"/></a></li>
              <li><a target="_blank" rel="noopener" href="https://twitter.com/salussafetyapp"><font-awesome-icon icon="fab fa-twitter" size="xl" :style="{ color: 'white' }"/></a></li>
              <li><a target="_blank" rel="noopener" href="https://www.facebook.com/salussafetyapp"><font-awesome-icon icon="fab fa-facebook-square" size="xl" :style="{ color: 'white' }"/></a></li>
              <li><a target="_blank" rel="noopener" href="https://www.instagram.com/salussafety.io/"><font-awesome-icon icon="fab fa-instagram" size="xl" :style="{ color: 'white' }"/></a></li>
              <li><a target="_blank" rel="noopener" href="https://www.youtube.com/c/SalusSafetyApp"><font-awesome-icon icon="fab fa-youtube" size="xl" :style="{ color: 'white' }"/></a></li>
            </ul>
          </div><!--icons-footer-->
        </div><!--d-flex-->
        <v-row>
          <div class="col-lg-6">
            <div class="email-capture">
              <h4>INDUSTRY INSIGHTS YOU WON’T DELETE. DELIVERED TO YOUR INBOX.</h4>
              <!-- <form>
                <input type="text" placeholder="Your Email Address" name="mail" class="email-input" required>
                <button disabled type="submit" class="email-subscribe-btn">Subscribe</button>
              </form> -->
            </div>
            <div class="awards">
                                </div><!--awards-->
          </div><!--col-->
          <div class="col-lg-6 menu-links">
            <div class="row">
              <div class="col-6 col-md-4">
                <div class="menu-footer">
                  <ul><li><a href="https://salussafety.io/product">Product</a></li>
                    <li><a href="https://salussafety.io/customers">Customers</a></li>
                    <li><a href="https://salussafety.io/reviews">Reviews</a></li>
                    <li><a href="https://salussafety.io/pricing">Pricing</a></li>
                  </ul>
                </div><!--menu-footer-->
              </div><!--col-->
              <div class="col-6 col-md-4">
                <div class="menu-resource-footer">
                  <ul><li><a href="https://salussafety.io/learn">Learn</a></li>
                    <li id="menu-item-659" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-659"><a href="https://salussafety.io/blog">Blog</a></li>
                    <li id="menu-item-1066" class="menu-item menu-item-type-post_type_archive menu-item-object-resources menu-item-1066"><a href="https://salussafety.io/resources">Resources</a></li>
                    <li id="menu-item-1624" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1624"><a href="https://salussafety.bamboohr.com/jobs/">Careers</a></li>
                  </ul>
                </div><!--menu-resource-footer-->
              </div><!--col-->
              <div class="col-md-4">
                <div class="menu-contact-footer">
                  <ul><li><a href="https://salussafety.io/contact-us">Contact Sales</a></li>
                    <li id="menu-item-1111" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1111"><a href="https://salussafety.io/contact-support">Contact Support</a></li>
                    <li id="menu-item-35" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-35"><a href="https://portal.salussafety.io/">Goto My Account</a></li>
                    <li id="menu-item-33" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-33"><a href="tel:1-800-521-6052">1-800-521-6052</a></li>
                    <li id="menu-item-34" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-34"><a href="mailto:connect@salussafety.io">connect@salussafety.io</a></li>
                  </ul>
                </div><!--menu-contact-footer-->
              </div><!--col-->
              <div class="col-12">
                <div class="footer-store-img">
                  <a class="" target="_blank" href="https://apps.apple.com/ca/app/salus-pro/id1238368091">
                    <img width="300" height="92" src="@/assets/apple-store-small.png" alt="" loading="lazy" />
                  </a>
                  <a class="" target="_blank" href="https://play.google.com/store/apps/details?id=com.calicologic.salus&hl=en_CA">
                    <img width="300" height="91" src="@/assets/google-store-small.png" alt="" loading="lazy" />
                  </a>
                </div><!--store-repeater-->
              </div>
            </div>
          </div>
        </v-row>

        <div class="sub-footer">
          <div class="centered-content">
            <p>&copy; 2022 Salus Technologies Inc.</p>
            <img src="@/assets/salus-shield.png" />
            <a href="https://salussafety.io/terms-of-service">Terms</a>
            <a href="https://salussafety.io/privacy-policy">Privacy</a>
          </div>
        </div>
      </div><!--container-->
    </footer>
</template>

<script>

import Logo from "@/assets/salus-logo-light-cropped.svg";

export default {
  components: {
      Logo,
  }
}
</script>

<style scoped>
.logo-social-container {
    flex-direction: row;
    justify-content: space-between;
    display: flex;
    margin-bottom: 100px;
}
.social-container {
    display: inline-block;
}
.social-container ul{
    display:flex
}
.social-container li{
    padding-right: 12px;
    list-style: none;
    cursor: pointer;
    padding: 0 12px;
}
.social-container li:first-child{
    padding-left: 0px;
}
.menu-links li{
    list-style: none;
}
.menu-links a{
    color: white;
    text-decoration: none;
    font-size: 14px;
    text-transform: uppercase;
}
.menu-links a:hover{
  opacity: 0.8;
}
.footer-store-img {
    display: flex;
    padding-left: 24px;
    margin-top: 60px;
}
.footer-store-img img{
    width: 100%;
    max-width: 165px;
    height: auto;
}
.footer-store-img a:first-child{
  margin-right: 16px;
}
.salus-logo-container svg {
  height: 21.75px;
  width: 145px;
}
.email-capture h4{
  text-transform: uppercase;
  margin-bottom: 60px;
  font-size: 26px;
  font-weight: 900;
  font-family: 'Lato', sans-serif;
  line-height: 1.2;
}
.email-input {
  border-bottom: 1px solid #faf2fa;
  width: 315px;
  font-size: 15px;
  height: 42px;
}
.email-input::placeholder {
  color: white;
}
.email-subscribe-btn {
  align-items: center;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px solid #62abff;
  color: #62abff;
  width: 200px;
  margin-left: 10px;
}
.email-subscribe-btn:hover {
  color: white;
}
.sub-footer {
  border-top: 1px solid rgba(219, 219, 219, 0.2);
  margin-top: 60px;
  justify-content: center;
  flex-direction: row;
  display: flex;
}
.centered-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 350px;
  margin-bottom: 35px;
  padding-top: 14px;
}
.centered-content p {
  font-size: 13px;
  color: #425461;
  margin: 0;
}
.centered-content a {
  text-decoration: none;
  font-size: 13px;
  color: #425461;
}
.centered-content img {
  max-width: 40px;
}
.container {
  padding: 0 30px;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1250px;
  }
}
@media (min-width:992px) {
  .centered-content {
    margin-right: 110px;
  }
}
@media (min-width:575px) {
  .email-input {
  width: 315px;
  }
  .email-subscribe-btn {
    width: 200px;
  }
}
@media (max-width: 768px) {
  .logo-social-container {
    display: block;
  }
  .social-container ul{
    padding: 0;
    margin-top: 30px;
  }
}
</style>
