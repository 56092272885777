import Vue from "vue";
import axios from "axios";
import store from "@/store";

axios.defaults.baseURL = process.env.VUE_APP_API_URL || "";
axios.interceptors.request.use(
  (config) => {
    const publicPages = ["/"];
    const authRequired = !publicPages.includes(config.url);
    const isTokenSet = store.getters["auth/isTokenSet"];
    const token = store.getters["auth/token"];

    if (authRequired && isTokenSet) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// noinspection JSUnusedGlobalSymbols
const VueAxiosPlugin = {
  install: (Vue) => {
    Vue.axios = axios;
    window.axios = axios;
    Object.defineProperties(Vue.prototype, {
      axios: {
        get() {
          return axios;
        },
      },
      $axios: {
        get() {
          return axios;
        },
      },
    });
  },
};

Vue.use(VueAxiosPlugin);

export default VueAxiosPlugin;
