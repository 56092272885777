import Vue from "vue";
import App from "@/app/App.vue";
import "@/plugins/axios";
import vuetify from "@/plugins/vuetify";
import router from "@/plugins/router";
import store from "@/store";

Vue.config.productionTip = false;

new Vue({
  el: "#app",
  vuetify,
  router,
  store,
  render: (h) => h(App),
});
