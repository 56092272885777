import api from "@/services/api/endpoints";

const state = {
  isDialogOpen: false,
  isSuccess: false,
  isSaveComplete: false,
  message: "",
};

const getters = {
  dialog: (state) => state.isDialogOpen,
  isSuccess: (state) => state.isSuccess,
  isSaveComplete: (state) => state.isSaveComplete,
  message: (state) => state.message,
};

const actions = {
  open({ commit }) {
    commit("open");
  },
  close({ commit }) {
    commit("close");
  },
  async submit({ commit }, answers) {
   commit("reset");
    try {
      const submissionResult = await api.submit(answers);
      if (submissionResult.status === 200 && submissionResult.data.isSuccess) {
        commit("formSubmitted", {
          isSaveComplete: true,
          message: submissionResult.data.message,
        });
      } else {
        commit("formFailed", {
          isSaveComplete: true,
          message: submissionResult.data.message,
        });
      }
    } catch (e) {
      commit("formFailed", {
        isSaveComplete: true,
        message: "There was an unexpected error!"
      });
    }
  },
};

const mutations = {
  open(state) {
    state.isDialogOpen = true;
    state.isSaveComplete = false;
    state.message = ""
    state.isSuccess = false;
  },
  close(state) {
    state.isDialogOpen = false;
    state.isSaveComplete = false;
    state.message = ""
    state.isSuccess = false;
  },
  formSubmitted(state, { isSaveComplete, message }){
    state.isSaveComplete = isSaveComplete;
    state.message = message;
    state.isSuccess = true;
  },
  formFailed(state, { isSaveComplete, message }){
    state.isSaveComplete = isSaveComplete;
    state.message = message;
    state.isSuccess = false;
  },
  reset(state){
    state.isSaveComplete = false;
    state.message = "";
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
