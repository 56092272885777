import api from "../src/services/api/endpoints";

const months_per_year = 12;
const rates = api.getRates();
const salusParameters = api.getSalusParameters();

let message = "";

function sum(a, b) {
  return a + b;
}

function findAnswer(questionnaire, questionId) {
  //TODO: refactor to something more clever
  let result = 0;
  questionnaire.forEach((question) => {
    if (question.id === questionId) {
      result = question.value;
    } else {
      question.subQuestions.forEach((answer) => {
        if (answer.id === questionId) {
          result = answer.value;
        } else if (answer.subQuestions != null) {
          answer.subQuestions.forEach((subQuestion) => {
            if (subQuestion.id === questionId) {
              result = subQuestion.value;
            }
          });
        }
      });
    }
  });
  console.log("Question  " + questionId + ": " + result);
  return result;
}
function findRate(type, quantity) {
  const baseRates = rates[0]["base"];
  const subcontractorsRates = rates[1]["subcontractors"];
  const rateType = type === "worker" ? baseRates : subcontractorsRates;

  const value = rateType.find((r) => {
    if (quantity >= r.min && quantity <= r.max) {
      console.log(type + " rate: " + r.rate);

      return r.rate;
    }
  });

  return value.rate;
}
function findSubcontractorRate(questionnaire) {
  let subcontractorMessage = "";
  let qtySubcontractors = findAnswer(questionnaire, "avgNumSubcontractors");
  //let collectsSafetyForms = findAnswer(questionnaire, "collectSafetyForms");
  let rateForSubcontractorsQty = findRate("subcontractor", qtySubcontractors);
  let totalSubsRate = salusParameters["salus-subs-annual-fee"];
  let minSubcontractors = salusParameters["salus-min-subs"];

  if (qtySubcontractors > 0 && qtySubcontractors < minSubcontractors) {
    subcontractorMessage = "There must be at least 5 subcontractors";
    return {
      totalSubsRate: 0,
      subcontractorMessage,
    };
  }

  if (qtySubcontractors === minSubcontractors) {
    totalSubsRate = 0;
  }
  totalSubsRate += rateForSubcontractorsQty * qtySubcontractors;
  return {
    totalSubsRate,
    subcontractorMessage,
  }; // subcontractor rate is per year;
}
function sumInputtedHours(questionnaire) {
  return (
    (findAnswer(questionnaire, "hoursSharingChangesPerMonth") + //.providePaperSafetyManual.hoursSharingChangesPerMonth +
      findAnswer(questionnaire, "hoursManagingFormsPerMonth") + //.collectPaperFormsDaily.hoursManagingFormsPerMonth +
      findAnswer(questionnaire, "hoursCollectingPerMonth") + //.completeReportsOnSafetyProgram.hoursCollectingPerMonth +
      findAnswer(questionnaire, "hoursCompletingPerMonth") + //.completeReportsOnSafetyProgram.hoursCompletingPerMonth +
      findAnswer(questionnaire, "hoursManagingPerMonth") + //.trackWorkerCertifications.hoursManagingPerMonth +
      findAnswer(questionnaire, "hoursSharingPerMonth") + //.trackWorkerCertifications.hoursSharingPerMonth +
      findAnswer(questionnaire, "hoursTrackingPerMonth") + //.completeAssetInspection.hoursTrackingPerMonth +
      findAnswer(questionnaire, "hoursTrackingSchedulesPerMonth") + //.completeAssetInspection.hoursTrackingSchedulesPerMonth +
      findAnswer(questionnaire, "hoursCollectingFormsPerMonth") + //.employSubContractedCompanies.hoursCollectingFormsPerMonth +
      findAnswer(questionnaire, "hoursManagingOrientationsPerMonth")) * //.employSubContractedCompanies.hoursManagingOrientationsPerMonth) *
    12
  );
}

export default {
  sum: sum,
  checkFormAnswers(questionnaire) {
    //Check if all questions & sub-questions have answers/values
    //Display message error about needing for all answers
    //TODO: Check with client if some questions may pass in blank or just verify the ones that are required to
    let questionQty = questionnaire.length;
    let totalQuestionAnswered = 0;
    let hasQuestionsAnswered = false; // Check main 6 questions aren't all answered with "No"
    for (const question of questionnaire) {
      for (const subQuestion of question.subQuestions) {
        if (!question.value) {
          // If they answered no to the main question, clear out the values of the subquestions
          subQuestion.value = null;
        }
        if (subQuestion.subQuestions && !subQuestion.value) {
          for (const subSubQuestion of subQuestion.subQuestions) {
            subSubQuestion.value = null;
          }
        }
      }
    }
    for (let i = 0; i < questionnaire.length; i++) {
      if (
        questionnaire[i].value !== null &&
        questionnaire[i].value !== "" &&
        questionnaire[i].value !== " "
      ) {
        totalQuestionAnswered += 1;
      }
      for (let j = 0; j < questionnaire[i].subQuestions.length; j++) {
        if (questionnaire[i].value === true) {
          questionQty += 1;
          hasQuestionsAnswered = true;
          if (
            questionnaire[i].subQuestions[j].value !== null &&
            questionnaire[i].subQuestions[j].value !== ""
          ) {
            totalQuestionAnswered += 1;
          }

          if (questionnaire[i].subQuestions[j].subQuestions) {
            for (
              let k = 0;
              k < questionnaire[i].subQuestions[j].subQuestions.length;
              k++
            ) {
              if (questionnaire[i].subQuestions[j].value === true) {
                questionQty += 1;

                if (
                  questionnaire[i].subQuestions[j].subQuestions[k].value !==
                    null &&
                  questionnaire[i].subQuestions[j].subQuestions[k].value !== ""
                ) {
                  totalQuestionAnswered += 1;
                }
              }
            }
          }
        }
      }
    }

    if (questionQty !== totalQuestionAnswered || !hasQuestionsAnswered) {
      message =
        "Please double-check your answers. We need comprehensive information to finish the calculations.";
      return {
        isFormValid: false,
        formMessage: message,
      };
    }
    return {
      isFormValid: true,
      formMessage: message,
    };
  },
  calculateCostBenefit(questionnaire) {
    //Calculate cost benefit based on the answers
    //Return false and display message if something is not correct
    const qtyWorkers = findAnswer(questionnaire, "avgNumActiveWorkers");
    const qtyPaperSheetsUsed =
      findAnswer(questionnaire, "pageCount") *
      findAnswer(questionnaire, "manualsPrintedPerYear"); // no. of sheets of paper

    //Salus Cost Calculation: for salus base vs salusPro
    let salusCost = 0;
    if (qtyWorkers <= salusParameters["salus-base-max-workers"]) {
      salusCost = salusParameters["salus-base-flat-cost"];
    } else if (qtyWorkers <= salusParameters["salusPro-max-workers"]) {
      salusCost = salusParameters["salusPro-flat-cost"];
    } else {
      salusCost = salusParameters["salusPro-platform-fee"];
    }

    const rateForWorkersQty = findRate("worker", qtyWorkers);
    if (!rateForWorkersQty) {
      message = `There is currently no plan to accommodate ${qtyWorkers} workers`;
      return false;
    }
    salusCost += rateForWorkersQty * qtyWorkers * months_per_year;

    // Include subcontractor rate
    if (findAnswer(questionnaire, "employSubContractedCompanies")) {
      const { totalSubsRate, subcontractorMessage } =
        findSubcontractorRate(questionnaire);
      salusCost += totalSubsRate;
      message = subcontractorMessage;

      if (message.length > 0) {
        return {
          isCalculationValid: false,
          apiPayload: {},
          calculationMessage: message,
        };
      }
    }

    //Final results calculations
    const totalHours = sumInputtedHours(questionnaire);
    const paperCost = qtyPaperSheetsUsed * salusParameters["salus-sheet-cost"];
    const workerCost = totalHours * salusParameters["general-worker-wage"];
    const currentCost = Math.round(paperCost + workerCost);
    const savingPercentage = Math.round((1 - salusCost / currentCost) * 100);

    console.log("Salus Cost: " + salusCost);

    if (totalHours === 0 && qtyPaperSheetsUsed === 0 && currentCost === 0) {
      message =
        "Please double-check your answers. We need comprehensive information to finish the calculations.";
      return {
        isCalculationValid: false,
        apiPayload: {},
        calculationMessage: message,
      };
    }

    const qtySubcontractors = findAnswer(questionnaire, "avgNumSubcontractors");

    const apiPayload = {
      adminTime: totalHours,
      volumeOfPaper: qtyPaperSheetsUsed,
      estTotalCost: currentCost,
      estCostBenefit:
        savingPercentage > 0 && savingPercentage < 100 ? savingPercentage : 0,
      workerCount: qtyWorkers ?? 0,
      subcontractorCount: qtySubcontractors ?? 0,
    };

    //TODO: Remove this log for production after testing in staging
    console.log(
      "Results: " +
        totalHours +
        ", " +
        qtyPaperSheetsUsed +
        ", " +
        currentCost +
        ", " +
        apiPayload.estCostBenefit
    );
    return {
      isCalculationValid: true,
      apiPayload: apiPayload,
      calculationMessage: message,
    };
  },
};
