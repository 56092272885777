const state = {
  isDialogOpen: false,
  //message: "",
};

const getters = {
  dialog: (state) => state.isDialogOpen,
  //message: (state) => state.message,
};

const actions = {
  open({ commit }) {
    // commit("setMessage", "");
    commit("open");
  },
  close({ commit }) {
    commit("close");
  },
  // setMessage({ commit }) {
  //   commit("setMessage", "");
  // },
};

const mutations = {
  open(state) {
    state.isDialogOpen = true;
  },
  close(state) {
    state.isDialogOpen = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
