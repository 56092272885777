import axios from "axios";

// Hard coded constants used to calculations
import questions from "../../static-data-source/questionnaire.json";
import salusParameters from "../../static-data-source/salus-base-parameters.json";
import rates from "../../static-data-source/salus-rates.json";

export default {
  // Post data to backend
  async submit(calculationResults) {
    return await axios.post("/submit/", calculationResults);
  },
  //Get questions to render
  //TODO: Make it come from the backend api, maybe make a portal for client to change it
  getQuestions: function () {
    return questions;
  },
  getSalusParameters: function () {
    return salusParameters;
  },
  getRates: function () {
    return rates;
  },
};
