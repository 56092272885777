<template>
  <div>
    <div
      v-bind:id="question.id"
      v-for="(question, index) in questions"
      :key="question.id"
      :class="hasParent ? 'inner' : 'outer'"
    >
      <div class="question">
        <div class="question-title">
          <div class="number" v-if="!hasParent">
            <span>{{ index + 1 }}</span>
          </div>
          <label :for="question.id">
            {{ question.title + " *" }}
          </label>
        </div>
        <v-row>
          <v-col v-if="question.type === 'radio'">
            <v-radio-group row v-model="question.value">
              <v-radio
                key="y"
                label="Yes"
                v-bind:value="true"
                :light="!hasParent"
              ></v-radio>
              <v-radio
                key="n"
                label="No"
                v-bind:value="false"
                :light=!hasParent
              ></v-radio>
              <!-- for this third radio button, vbind a truthy value...
                   I know, this is pretty cursed, but we can fix it later -->
              <v-radio
                v-if="question.hasBothOption"
                key="b"
                label="Both"
                v-bind:value="'truthy'"
                @click="
                  () => {
                    question.bothValue = true;
                  }
                "
                :light=!hasParent
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col sm="3" v-if="question.type !== 'radio'">
            <v-text-field
              :id="question.id"
              v-if="question.type === 'number'"
              v-model.number="question.value"
            />
            <v-select
              v-if="question.type === 'select'"
              v-bind:items="question.options"
              v-model="question.value"
              label="Select frequency"
              single-line
            />
          </v-col>
        </v-row>
      </div>
      <div class="sub-questions" v-if="question.value && question.subQuestions">
        <SubQuestions
          v-bind:questions="question.subQuestions"
          :has-parent="true"
        >
        </SubQuestions>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    questions: [],
    hasParent: Boolean,
  },
  beforeCreate: function () {
    //config to use component inside same component #recursive-components
    //https://v2.vuejs.org/v2/guide/components-edge-cases.html?redirect=true#Recursive-Components
    this.$options.components.SubQuestions =
      require("./Questionnaire.vue").default;
  },
  components: {},
};
</script>

<style scoped>
div.number {
  border-radius: 50%;
  border: thin solid #df8e00;
  color: #df8e00;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  min-width: 2rem;
  min-height: 2rem;
}

div.sub-questions {
  padding: 1rem;
  background: #1d3547;
}

div.outer {
  margin-bottom: 2rem;
}

div.outer:last-child {
  margin-bottom: 0;
}

div.outer > div.question {
  color: #021e33;
  background: #f0f4fb !important;
  padding: 1rem;
  z-index: 1;
  position: relative;
}

div.question-title {
  display: flex;
  align-items: center;
}

label {
  font-weight: 600;
}
</style>
