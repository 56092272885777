import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faInstagram, faFacebookSquare, faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'

Vue.component('font-awesome-icon', FontAwesomeIcon) // Register component globally
library.add(faInstagram, faFacebookSquare, faLinkedinIn, faTwitter, faYoutube) // Include needed icons

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: { dark: { primary: "#63ABFF", secondary: "#80EF63", }, },
  },
});
