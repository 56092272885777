import Vue from "vue";
import Vuex from "vuex";

import pageCommands from "@/store/modules/landingPage";
import modalCommands from "@/store/modules/modalFormSubmit";

import vuexLocal from "@/plugins/vue-persist";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    landingPage: pageCommands,
    modal: modalCommands,
  },
  plugins: [vuexLocal.plugin],
});

export default store;
