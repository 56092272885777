<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600px" height="500px">
      <v-card color="white">
        <v-btn class="btn-close" @click.native="close">
          <v-icon x-large>mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          <div id="modal-title">
            Get your results as a pdf. <br />
            Simply enter your details below then head to your inbox.
          </div>
        </v-card-title>
        <form id="mktoForm_1245"></form>
        <v-card-text>
          <div class="card-padding">
            <v-text-field
              dense
              single-line
              type="input"
              label="First name"
              v-model="firstName"
              class="modal-text-field"
              :rules="[rules.required]"
              height="75px"
              variant="plain"
            />
            <v-text-field
              dense
              single-line
              type="input"
              label="Last Name"
              v-model="lastName"
              class="modal-text-field"
              :rules="[rules.required]"
              height="75px"
              variant="plain"
            />
            <v-text-field
              dense
              single-line
              type="input"
              label="Company Name"
              v-model="companyName"
              class="modal-text-field"
              :rules="[rules.required]"
              height="75px"
              variant="plain"
            />
            <v-text-field
              dense
              single-line
              type="input"
              label="Email"
              v-model="email"
              class="modal-text-field"
              :rules="[rules.required, rules.emailMatch]"
              height="75px"
              variant="plain"
            />
            <!-- <Script>
          // MktoForms2.loadForm("//app-ab00.marketo.com", "785-UHP-775", 1057, function(form) {
          //   // Add an onSuccess handler
          //   form.onSuccess(function(values, followUpUrl) {
          //     // Get the form's jQuery element and hide it
          //     form.getFormElem().hide();
          //     // Return false to prevent the submission handler from taking the lead to the follow up url
          //     return false;
          //   });
          // });
          </Script> -->
            <v-alert
              v-if="isSaveComplete && !isSuccess"
              type="error"
              border="left"
              class="message"
            >
              {{ message }}
            </v-alert>
            <v-alert
              v-if="isSaveComplete && isSuccess"
              type="success"
              border="left"
              class="message"
            >
              {{ message }}
            </v-alert>
            <v-btn
              block
              :disabled="isSubmitDisabled"
              x-large
              color="secondary"
              class="btn-green"
              @click.native="save"
              :loading="loading"
            >
              Get Your Report
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["payload"],
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      companyName: "",
      rules: {
        required: (value) => !!value || "Required",
        emailMatch: (value) =>
          !value ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(value) ||
          "E-mail must be valid",
      },
      loading: false,
    };
  },
  watch: {
    isSaveComplete(isComplete) {
      if (isComplete) {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      dialog: "modal/dialog",
      isSaveComplete: "modal/isSaveComplete",
      message: "modal/message",
      isSuccess: "modal/isSuccess",
      //message: "batch/create/message",
    }),
    isSubmitDisabled() {
      return (
        this.isSaveComplete ||
        !this.firstName ||
        !this.lastName ||
        !this.email ||
        !this.companyName
      );
    },
  },
  methods: {
    ...mapActions({
      close: "modal/close",
      submit: "modal/submit",
    }),
    async save() {
      this.loading = true;
      const answers = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        companyName: this.companyName,
        workerCount: this.payload.workerCount,
        subcontractorCount: this.payload.subcontractorCount,
        adminTime: this.payload.adminTime,
        volumeOfPaper: this.payload.volumeOfPaper,
        estTotalCost: this.payload.estTotalCost,
        estCostBenefit: this.payload.estCostBenefit,
      };
      await this.submit(answers);
      if (this.isSuccess) {
        setTimeout(() => {
          window.location.href = "https://salussafety.io/";
        }, 5000);
      }
    },
  },
};
</script>
<style>
.btn-close {
  size: 50px;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  cursor: pointer;
  font-weight: bold;
  background-color: white !important;
  box-shadow: none !important;
  margin-top: 20px;
}

.v-btn > .v-btn__content .v-icon {
  color: #011320 !important;
}

.btn-green {
  color: #011320 !important;
  font-weight: bold !important;
}

.v-btn.v-btn--disabled.btn-green:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
  color: #011320 !important;
  border-style: solid;
  border-color: #011320;
  font-weight: bold;
}

#modal-title {
  color: #011320 !important;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 75px;
  font-weight: bold;
  font-size: 25px;
  width: 325px;
  word-break: keep-all;
}

.theme--dark.v-card > .v-card__text {
  color: #011320 !important;
}

.modal-text-field {
  margin-top: 30px;
  margin-bottom: 30px;
  height: 75px;
  background-color: #f1f1f1;
  border-radius: 8px;
}

.modal-text-field input {
  color: #1a1a1a !important;
  font-weight: bold;
  font-size: 20px;
  margin-left: 15px;
}

.modal-text-field .v-label {
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  padding-left: 15px;
}

.card-padding {
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
}

.message {
  font-size: 20px;
}

.v-progress-circular {
  margin: 1rem;
}
</style>
